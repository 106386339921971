<template>
  <div class="home">
    
    <MenuPrincipal />
    <interPage />
    <InterServices />
    <Block1/>
    <Block2/>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import interPage from '@/components/interPage.vue'
import InterServices from '@/components/InterServices.vue'
import Block1 from '@/components/Block1.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    interPage,
    InterServices,
    Block1,
    Block2
  }
}
</script>
