<template>
<div>
<div class="menu__principal cc">
<div class="menu__principal_hijo bc">
	<v-btn 
  color="#00A4B8" left rounded
  large to="/" class="logo__principal sc ">
		<v-icon style="margin-right: 5px;">
     mdi-home-outline  
    </v-icon>
    {{logo}}
	</v-btn>



  <div class="menu__links  ec">
   



    
    <v-btn
    href="https://api.whatsapp.com/send?phone=525530684965"
    class="hideOnMovil"
    style="color: white;margin-left: 15px;"
    large rounded
    color="#00A4B8">
      <v-icon
      class="icon__movil"
      >mdi-whatsapp</v-icon>
      <span class="hideOnMovil">55 3068 4965</span>
    </v-btn>
  </div>

</div>
</div>


</div>
</template>

<script>
import router from '../router/index.js'
  export default {
    data () {
      return {
      	logo:'Traducciones FOCUS',
        drawer: null,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
      }
    },
    methods:{
      redirigir(ruta){
        router.push({ 
          name: ruta
        });
      },
    }
  }
</script>

<style>
.menu__principal{
	width: 100%; height: 35px; 
  top: 20px;position: absolute;
  z-index: 3000;
}
.icon__movil{margin-right: 15px;}
.menu__principal_hijo{width: 95%; height: 100%;}
	.logo__principal{
	  width: auto !important;
		font-weight: 600;font-size: 25px;
    color: var(--colorEnlaces);
    text-decoration: none;color: white!important;
    
	} 

  .menu__links{height: 100%;}
  .menu__link{
    height: 100%;
    margin-left: 15px; 
    text-decoration: none;
    font-family: 'poppins'!important;
    font-size: 16px;
    color: var(--colorEnlaces) !important;
    border-bottom: solid 2px transparent;
    transition: .7s;
  }
  .menu__link:hover{
    border-bottom: solid 2px white;
    transition: .7s;
  }
@media(max-width: 900px)
{
  .icon__movil{margin-right: 0px;}
	.menu__principal{width: 90%; margin-left: 5%;}
	.logo__principal{width: 70%;font-size: 16px;}
}
</style>