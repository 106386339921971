<template>
  <div class="home">
    
    <MenuPrincipal />
    <interCotiPage />
    <cotizarComponent />
    <Block2 />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import interCotiPage from '@/components/interCotiPage.vue'
import cotizarComponent from '@/components/cotizarComponent.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    interCotiPage,
    cotizarComponent,
    Block2
  }
}
</script>
