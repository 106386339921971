<template>
  <div class="home">
    
    <MenuPrincipal />
    <interContactPage />
    <contactComponent />
    <Block2 />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import interContactPage from '@/components/interContactPage.vue'
import contactComponent from '@/components/contactComponent.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    interContactPage,
    contactComponent,
    Block2
  }
}
</script>
