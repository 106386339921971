<template>
  <div class="home">
    
    <LandingMenu />
    <LandingHome />
    <LandingText1 />
    <LandingText2 />
    <LandingText3 />
    <Services />
    
    
    <Block2/>

  </div>
</template>

<script>
// @ is an alias to /src
import LandingMenu from '@/components/LandingMenu.vue'
import LandingHome from '@/components/LandingHome.vue'
import Services from '@/components/Services.vue'
import InterServices2 from '@/components/InterServices2.vue'
import LandingText3 from '@/components/LandingText3.vue'
import LandingText1 from '@/components/LandingText1.vue'
import LandingText2 from '@/components/LandingText2.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    LandingMenu,
    LandingHome,
    Services,
    InterServices2,
    LandingText2,
    LandingText1,
    LandingText3,
    Block2
  }
}
</script>
