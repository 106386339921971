<template>
<div class="interDataNew cc">
<v-alert
    :value="alert"
    class="alertSw"
    type="success"
    color="#001F7A"
    :transition="alertTransition"
    >
  Tus datos fueron enviados con éxito, pronto te <br>daremos una respuesta.
</v-alert>

<div class="interDataSn bc">
	<div class="interTextData bc">
		
		<div class="dataInfoParr topEsp topEspDos">
			Cotiza tu proyecto con nosotros, tenemos amplia experiencia <br class="hideOnMovil">para entregarte 
			un trabajo <br class="hideOnMovil">impecable
		</div>

		<div class="inputPage topEsp topEspDos">
				<v-text-field
					solo v-model="nombre"
          placeholder="Nombre"
        ></v-text-field>
		</div>
		<div class="inputPage topEsp">
				<v-text-field
					solo v-model="apellido"
          placeholder="Apellido"
        ></v-text-field>
		</div>
		<div class="inputPage">
				<v-text-field
					solo v-model="correo"
          placeholder="Correo electrónico"
        ></v-text-field>
		</div>
		<div class="inputPage">
				<v-text-field
					solo v-model="telefono"
          placeholder="Numero de teléfono"
        ></v-text-field>
		</div>
		<div class="" style="width: 100%;">
				<v-textarea
				placeholder = "Comentarios u observaciones"
				v-model="comments"
				solo></v-textarea>
			</div>
		<div style="width: 100%;" class="inputPage">
				<v-file-input
					v-model="archivo"
					solo prepend-icon=""
          placeholder="Adjunta tu archivo"
        ></v-file-input>
		</div>
		
		<div class="inputPage sc">
			<v-btn 
			:loading="loading"
			@click="enviarDatos()"
			large style="color: white;" color="#00A4B8">
				Enviar datos
			</v-btn>
			<div class="successData">
				Datos enviados
			</div>
		</div>

		<div style="font-size: 16px;" class="dataInfoParr topEsp topEspTres">
			Si lo prefieres contáctanos<br> 
			<span style="font-weight: 300">
			55 3068 4965<br>
			contacto@traduccionesfocus.com
		</span>
		</div>


	</div>

	<!---------------------->
	<div class="interSlider hideOnMovil">
		<img class="imageSliderInter" src="@/assets/image.jpg">
	</div>
</div>
</div>
</template>
<script>
export default {
name: 'cotizar-component',
	data: () => ({
		alert:false,
		alertTransition:'scroll-x-reverse-transition',
		loading:false,


		nombre: null,
		apellido: null,
		correo: null,
		telefono: null,
		archivo: null,
		comments:null
  }),
  methods:{
  	enviarDatos(){
  		var vm = this;
  		vm.loading = true;


  		const nombre = this.nombre;
			const apellido = this.apellido;
			const correo = this.correo;
			const telefono = this.telefono;
			const archivo = this.archivo;
			const comments = this.comments;
			
			const packagePost = new FormData();


			packagePost.append('nombre',nombre);
			packagePost.append('apellido',apellido);
			packagePost.append('correo',correo);
			packagePost.append('telefono',telefono);
			packagePost.append('archivo',archivo);
			packagePost.append('comments',comments);

			$.ajax({
    			data:  packagePost, 
          url:   ''+serverName+''+'/cotizaciones.php', 
          type:  'post', 
	          contentType: false,
	          processData: false,

          success:  function (res) {
          	vm.alert=true;
          	vm.loading = false;
          	setTimeout(function(){ 
                vm.alert=false; 
            }, 6000);

          	vm.nombre = '';
						vm.apellido = '';
						vm.correo = '';
						vm.telefono = '';
						vm.archivo = null;
						vm.comments = '';



          }

    		})

  	}
  },
}
</script>

<style>
.interDataNew{
	width: 100%; height: 100vh;
	align-content: flex-end;
	align-items: flex-end;
}
.interDataSn{
	width: 90%; height: 95%;
}
.successData{display: none;margin-left: 5px;}
	.interTextData{
		width: 62%; height: 100%;
		align-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
	}
		.text__main__cont_inter{
			margin-top: 45px;
			width: 47%;
		}
		.tex__main_tit_inter{font-size: 1.1rem;}
		.topEsp{margin-top: 30px;}



	/************/
	.interSlider{
		width: 35%; height: calc(100% - 30px);
		background: black;
		margin-top: 30px;
		overflow: hidden;
		position: relative;
	}
	.interSlider::after{
		content: "FOCUS";
		position: absolute;
		z-index: 2000;
		bottom: 20px; right: 30px;
		color: white;
		font-size: 40px;
		font-weight: 600;
		letter-spacing: 2px;
	}
	.interSlider::before{
		content: "";
		z-index: 1000;
		position: absolute;
		background: var(--colorPrincipal);
		width: 100%; height: 100%;
		opacity: 0.3;
	}
	.imageSliderInter{height: 100%;z-index: 0;}





	.dataInfoParr{
		width: 100%; font-size: 19px;
		margin-top: 25px;font-weight: 500;
	}
	.inputPage{
		width: 48%; height: auto;
	}

@media(max-width: 900px)
{
@media(max-width: 900px)
{
.interDataNew{height: auto;}
.interTextData{width: 100%;}
.inputPage{width: 100%;}
.topEsp{margin-top: 0px;}
.topEspDos{margin-top:20px;}
.topEspTres{margin-top: 30px;}
}
}
</style>