<template>
<div class="landingHomeComponent cc">
<v-alert
    :value="alert"
    class="alertSw"
    type="success"
    color="#001F7A"
    :transition="alertTransition"
    >
  Tus datos fueron enviados con éxito, pronto te <br>daremos una respuesta.
</v-alert>

<div class="landingImageComp cc">
	<img 
	class="imageLanding" 
	src="@/assets/image2.jpg">
</div>

<div class="landingHomeSn bc">
	
	<!---->
	<div class="landingHomeTex csc">
		<h1 class="landingTit">
			Servicio de traducciones profesionales
		</h1>
		<p class="parrLanding">
			Cotiza con nosotros el documento que deseas traducir, en FOCUS realizamos traducciones fidedignas en múltiples idiomas y de todas las áreas, además te ofrecemos servicio de diseño e/o impresión de tus documentos (manuales, folletos, catálogos, etc).
		</p>
		<div class="linesLandingFh bc">
			<div class="lineLanding">
				<div class="iconLineLanding cc">
					<v-icon 
					style="font-size:25px;"
					color="white">
						mdi-check
					</v-icon>
				</div>
				<div class="textLineLanding cc">
					<h2 class="textLinetLand">
						Traducciones generales
					</h2>
				</div>
			</div>
			<div class="lineLanding">
				<div class="iconLineLanding cc">
					<v-icon 
					style="font-size:25px;"
					color="white">
						mdi-check
					</v-icon>
				</div>
				<div class="textLineLanding cc">
					<h2 class="textLinetLand">
						Área médica
					</h2>
				</div>
			</div>
			

			<div class="lineLanding">
				<div class="iconLineLanding cc">
					<v-icon 
					style="font-size:25px;"
					color="white">
						mdi-check
					</v-icon>
				</div>
				<div class="textLineLanding cc">
					<h2 class="textLinetLand">
						Área académica
					</h2>
				</div>
			</div>
			<div class="lineLanding">
				<div class="iconLineLanding cc">
					<v-icon 
					style="font-size:25px;"
					color="white">
						mdi-check
					</v-icon>
				</div>
				<div class="textLineLanding cc">
					<h2 class="textLinetLand">
						Área de diagnóstico 
					</h2>
				</div>
			</div>
		</div>

		<v-btn 
		@click="abrirFormulario()"
		class="hideOnDesktop"
		large color="info"
		>Solicitar cotización</v-btn>

	</div>
	<!---->

	<div class="landingFormFh cc">
		<div class="formSonCont cc">
		<div class="landingFormSn bc">
			<h4 class="landingFormTit">
				Contáctanos y recibe <br>una cotización 
			</h4>
			<p class="parrForm">
				Deja tus datos y adjunta tu archivo para recibir una cotización
			</p>

			<div class="inputFormFh" style="margin-top: 10px;">
				<v-text-field
				depressed flat v-model="nombre"
				placeholder="Tu nombre"  solo></v-text-field>
			</div>
			<div class="inputFormFh" style="margin-top: 10px;">
				<v-text-field
				depressed flat v-model="apellido"
				placeholder="Tu apellido"  solo></v-text-field>
			</div>

			<div class="inputFormFh">
				<v-text-field
				depressed flat v-model="telefono"
				placeholder="Tu telefono"  solo></v-text-field>
			</div>
			<div class="inputFormFh">
				<v-text-field
				depressed flat v-model="correo"
				placeholder="Tu correo electronico"  solo></v-text-field>
			</div>
			<div class="inputFormFh" style="width: 100%;height: 100px;overflow: hidden;">
				<v-textarea
				placeholder = "Comentarios u observaciones"
				style="height: 100px;"
				prepend-icon=""
				depressed flat v-model="comments"
				solo></v-textarea>
			</div>
			<div class="inputFormFh" style="margin-top: 25px; width: 100%;">
				<v-file-input
				prepend-icon=""
				depressed flat v-model="archivo"
				placeholder="Adjunta tu archivo"  solo></v-file-input>
			</div>
			<div class="inputFormFh ec" 
			style="width: 100%;margin-top: -5px;">
				<v-btn 
				:loading="loading"
				@click="enviarDatos()"
				large color="info" >Enviar datos</v-btn>
			</div>



			<div style="font-size: 16px; color: white;" class="dataInfoParr topEsp topEspTres">
			Si lo prefieres contáctanos<br> 
			<span style="font-weight: 300">
			55 3068 4965<br>
			contacto@traduccionesfocus.com
		</span>
		</div>
		</div>
		</div>
	</div>


</div>
</div>
</template>
<script>
export default {
name: 'cotizar-component',
	data: () => ({
		alert:false,
		alertTransition:'scroll-x-reverse-transition',
		loading:false,


		nombre: null,
		apellido: null,
		correo: null,
		telefono: null,
		archivo: null,
		comments:null
  }),
  methods:{
  	abrirFormulario(){
  		$('.landingFormFh').css('display','flex')
  	},
  	enviarDatos(){
  		var vm = this;
  		vm.loading = true;


  		const nombre = this.nombre;
			const apellido = this.apellido;
			const correo = this.correo;
			const telefono = this.telefono;
			const archivo = this.archivo;
			const comments = this.comments;

			const packagePost = new FormData();


			packagePost.append('nombre',nombre);
			packagePost.append('apellido',apellido);
			packagePost.append('correo',correo);
			packagePost.append('telefono',telefono);
			packagePost.append('archivo',archivo);
			packagePost.append('comments',comments);


			$.ajax({
    			data:  packagePost, 
          url:   ''+serverName+''+'/cotizaciones.php', 
          type:  'post', 
	          contentType: false,
	          processData: false,

          success:  function (res) {
          	vm.alert=true;
          	vm.loading = false;
          	setTimeout(function(){ 
                vm.alert=false; 
            }, 6000);

          	vm.nombre = '';
						vm.apellido = '';
						vm.correo = '';
						vm.telefono = '';
						vm.comments = '';
						vm.archivo = null;




          }

    		})

  	}
  },


}
</script>
<style>
.landingHomeComponent{
	width: 100%; height: 90vh;
	position: relative;
}
.successData2{
	margin-right: 10px; color: white;
	display: none;
}
.hideOnDesktop{display: none !important;}
.landingImageComp{
	width: 100%; height: 100%;
	overflow: hidden;
	position: absolute;
	left: 0; top: 0;
	background: black;
}
	.landingImageComp:before{
		content: "";position: absolute;
		width: 100%; height: 100%;
		background: var(--colorPrincipal);
		z-index: 1000;
		opacity: 0.45;
	}
	.imageLanding{
		width: 100%;overflow: hidden;
		z-index: 0;
	}
	
.landingHomeSn{
	width: 90%; height: 100%;
	
}
	
	.landingHomeTex{
		width: 52%; height: 100%;
		z-index: 2000;
	}
		.landingTit{
			font-size: 2.8rem;
			color: white;
			font-weight: 600;
			margin-top: 25px;
		}
		.parrLanding{
			color: white;
			width: 90%;
			margin-top: 15px;
		}
		/************/
		.linesLandingFh{
			width: 100%; height:115px;
			flex-wrap: wrap;
			align-content: space-between;
			margin-top: 5px;
		}
			.lineLanding{
				width: 47%; height: 43%;
				background: #011A78;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
				.iconLineLanding{
					width: 22%; height: 100%;
				}
				.textLineLanding{
					width: 78%; height: 100%;
					color: white !important;
				}
					.textLinetLand{
						font-size: 1rem;
						font-weight: 500;
						width: 100%;
					}




	.landingFormFh{
		width: 42%; height: 100%;
		align-content: flex-end;
		align-items: flex-end;
		z-index: 2000;
		
	}
		.formSonCont{
			width: 100%; height: 120%;
			background: var(--colorPrincipal);;
			margin-bottom: -35%;
		}
		.landingFormSn{
			width: 89%;height: 100%;
			flex-wrap: wrap;
			align-content: center;
			align-items: center;
		}

			.landingFormTit{
				color: white; 
				width: 100%; 
				text-align: center;
				font-weight: 600;
				font-size: 22px;
				margin-bottom: 15px;
			}
			.parrForm{
				color: white; text-align: center;
				width: 100%;
			}

			.inputFormFh{
				width: 48.5%; height: auto;
				margin-bottom: -5px;
			}

@media(max-width: 900px)
{
.hideOnDesktop{display: flex !important;}
.landingHomeComponent{height: 550px;}
.landingHomeTex{width: 100%;justify-content: flex-start;}
	.landingTit{font-size: 25px;margin-top: 100px; }
	.linesLandingFh{display: none;}
	.parrLanding{width: 100%;}

	.imageLanding{ height: 100%; width: auto!important;}

.landingFormFh{
	/**//**/
	display: none;
	/**/
	width: 100%; height: 800px;
	position: absolute !important; 
	top: -31vh !important; left: 0;
}
	.landingFormTit{margin-top: 270px;}
	.dataInfoParr{margin-top: 25px;}

}
</style>