<template>
  <div class="home">
    
    <MenuPrincipal />
    <interPage />
    <InterServices2 />
    <Block2 />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import interPage from '@/components/interPage.vue'
import InterServices2 from '@/components/InterServices2.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    interPage,
    InterServices2,
    Block2
  }
}
</script>
