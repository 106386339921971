<template>
  <div class="home">
    
    <MenuPrincipal />
    <interUnetePage />
    <formUnete />
    <Block2 />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuPrincipal from '@/components/MenuPrincipal.vue'
import interUnetePage from '@/components/interUnetePage.vue'
import InterServices from '@/components/InterServices.vue'
import formUnete from '@/components/formUnete.vue'
import Block2 from '@/components/Block2.vue'

export default {
  name: 'Home',
  components: {
    MenuPrincipal,
    interUnetePage,
    InterServices,
    formUnete,
    Block2
  }
}
</script>
